<template>
  <ui-form
    @submit="handleSubmit"
    :submitText="$t('actions.save')"
    @cancel="handleCancel"
  >
    <ui-form-field :label="$t('fields.name')" rules="required">
      <el-input name="name" type="text" v-model="company.name"></el-input>
    </ui-form-field>

    <ui-form-field :label="$t('fields.description')">
      <el-input type="text" v-model="company.description"></el-input>
    </ui-form-field>

    <ui-form-field :label="$t('labels.company.upload_logo')">
      <el-upload
        action=""
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :limit="1"
        :on-exceed="handleExceed"
        :file-list="fileList"
        :on-change="setLogo"
        class="background_form_item"
      >
        <el-button size="small" type="primary">{{
          $t("actions.click_to_upload")
        }}</el-button>
        <div slot="tip" class="el-upload__tip"></div>
      </el-upload>
      <ui-button
        type="red"
        @click="setDeletedLogo"
        class="background_form_item delete_button"
        v-if="logoExists"
      >
        {{ $t(deleteLogoText) }}
      </ui-button>
    </ui-form-field>

    <ui-form-field :label="$t('labels.building.upload_background')">
      <el-upload
        action=""
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :limit="1"
        :on-exceed="handleExceed"
        :file-list="fileList"
        :on-change="setBackground"
        class="background_form_item"
      >
        <el-button size="small" type="primary">{{
          $t("actions.click_to_upload")
        }}</el-button>
        <div slot="tip" class="el-upload__tip"></div>
      </el-upload>
      <ui-button
        type="red"
        @click="setDeletedBackground"
        class="background_form_item delete_button"
        v-if="backgroundExists"
      >
        {{ $t(deleteBackgroundText) }}
      </ui-button>
    </ui-form-field>

    <ui-form-field v-if="this.company.permissions && Array.isArray(this.company.permissions) && this.company.permissions.length > 0" :label="$tc('models.permissions')">
      <ui-list
        :disableXScroll="true"
        :headings="[
          $tc('models.scope'),
          $tc('models.permission'),
          '',
          '',
          $tc('models.allow'),
          // ''
        ]"
        :items="this.company.permissions"
      >
        <template v-slot:default="props">
          <ui-list-data type="tag" :text="props.item.scope" />
          <ui-list-data type="tag" :text="props.item.permission" />
          <ui-list-data />
          <ui-list-data />
          <ui-list-data
            ><el-checkbox v-model="props.item.hasPermission"
          /></ui-list-data>
        </template>
      </ui-list>
    </ui-form-field>
  </ui-form>
</template>


<script>
import UiForm from "@/components/ui/UiForm";
import UiFormField from "@/components/ui/UiFormField";
import UiList from "@/components/ui/UiList";
import UiListData from "@/components/ui/UiListData";
import {
  uploadCompanyLogo,
  uploadCompanyBackground,
  deleteLogo,
  deleteBackground,
  backgroundExists,
  logoExists,
} from "@/app/companies/api";
import UiButton from "@/components/ui/UiButton";
import { parseDateOrDefault } from "@/extras/";

export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
    fileList: [{}],
  },

  data() {
    return {
      //company: this.props.company
      companyLogo: "",
      companyBackground: "",
      deleteBackgroundText: "labels.company.delete_background",
      deleteLogoText: "labels.company.delete_logo",
      backgroundExists: false,
      logoExists: false,
      backgroundDeleted: false,
      logoDeleted: false,
      registrationLinks: [
        { name: "Hoved", url: "https://google.com/", date: Date.now() },
        { name: "Hoved", url: "https://google.com/", date: Date.now() },
        { name: "Hoved", url: "https://google.com/", date: Date.now() },
      ],
    };
  },

  components: {
    UiButton,
    UiForm,
    UiFormField,
    UiList,
    UiListData,
  },

  computed: {
    selectedPermissions: function () {
      return this.company.permissions.filter((perm) => perm.hasPermission);
    },
  },

  watch: {
    "company.permissions": {
      deep: true,
      handler: function () {
        this.$emit("update-selected-permissions", this.selectedPermissions);
      },
    },
  },

  methods: {
    async handleSubmit() {
      if (this.backgroundDeleted) {
        await this.deleteBackground();
      }
      if (this.logoDeleted) {
        await this.deleteLogo();
      }
      if (this.companyLogo !== "") {
        await uploadCompanyLogo(this.companyLogo.raw, this.company.id);
      }
      if (this.companyBackground !== "") {
        await uploadCompanyBackground(
          this.companyBackground.raw,
          this.company.id
        );
      }
      this.$emit("submit");
    },

    setLogo(picture) {
      this.companyLogo = picture;
    },

    setBackground(picture) {
      this.companyBackground = picture;
    },

    handleRemove(file, fileList) {},
    handlePreview(file) {},
    handleExceed(files, fileList) {
      this.$message.warning(
        `The limit is 1, you selected ${
          files.length
        } file this time, add up to ${files.length + fileList.length} totally`
      );
    },
    beforeRemove(file) {
      return this.$confirm(`Cancel the transfer of ${file.name} ?`);
    },

    handleCancel() {
      this.$emit("cancel");
    },

    setDeletedBackground() {
      this.deleteBackgroundText = "labels.building.deleted";
      this.backgroundDeleted = true;
    },

    setDeletedLogo() {
      this.deleteLogoText = "labels.company.deleted";
      this.logoDeleted = true;
    },

    deleteLogo() {
      deleteLogo(this.company.id);
    },

    deleteBackground() {
      deleteBackground(this.company.id);
    },

    async logoExistsFunction() {
      return await logoExists(this.company.id);
    },

    async backgroundExistsFunction() {
      return await backgroundExists(this.company.id);
    },

    parseDateOrDefault(dateString, def) {
      return parseDateOrDefault(dateString, def);
    },
  },

  created() {},

  async mounted() {
    this.logoExists = await this.logoExistsFunction();
    this.backgroundExists = await this.backgroundExistsFunction();
  },
};
</script>

<style scoped>
.el-button--primary {
  background-color: #f3f3f3;
  color: black;
  border-color: #f3f3f3;
  font-weight: 700;
}

.logo_form_item {
  display: inline-flex;
}

.background_form_item {
  display: inline-flex;
}

.delete_button {
  margin-left: 1em; 
}
</style>
