var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('el-tabs',{attrs:{"type":"border-card"}},[_c('el-tab-pane',{attrs:{"label":`${_vm.$t('actions.edit')} ${_vm.$tc('models.company')}`}},[_c('ui-card',{staticClass:"mb-8",attrs:{"heading":`${_vm.$t('actions.edit')} ${_vm.$tc('models.company')}`}},[(_vm.company)?_c('company-form',{attrs:{"company":_vm.company},on:{"submit":_vm.handleSubmit,"cancel":_vm.handleCancel,"update-selected-permissions":_vm.evtUpdateSelectedPermissions}}):_vm._e()],1)],1),_c('el-tab-pane',{attrs:{"label":_vm.$tc('links.registration_links', 2)}},[_c('ui-card',{staticClass:"mb-8",attrs:{"heading":_vm.$tc('links.registration_links', 2)}},[(_vm.registrationLinks)?_c('ui-list',{attrs:{"disableXScroll":true,"headings":[
            _vm.$t('fields.name'),
            _vm.$t('fields.reference'),
            _vm.$t('fields.expires'),
            _vm.$t('fields.actions'),
            // ''
          ],"items":_vm.registrationLinks},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ui-list-data',{attrs:{"text":props.item.name}}),_c('ui-list-data',{attrs:{"text":props.item.reference}}),(
                props.item.expires &&
                new Date(props.item.expires).getTime() < new Date().getTime()
              )?_c('ui-list-data',{staticStyle:{"color":"red"},attrs:{"text":new Date(props.item.expires).toLocaleDateString('en-US')}}):_c('ui-list-data',{attrs:{"text":props.item.expires
                  ? new Date(props.item.expires).toLocaleDateString('en-US')
                  : '-'}}),_c('ui-list-data',{attrs:{"small":""}},[_c('ui-link',{attrs:{"type":"danger","uppercase":"","size":"small"},on:{"click":function($event){return _vm.handleDeleteRegistraionLink(props.item.reference)}}},[_vm._v(_vm._s(_vm.$t("actions.delete")))]),_c('ui-link',{staticClass:"ml-2",attrs:{"type":"success","uppercase":"","size":"small"},on:{"click":function($event){return _vm.handleEditRegistraionLink(props.item)}}},[_vm._v(_vm._s(_vm.$t("actions.edit")))])],1)]}}],null,false,1066664942)}):_vm._e(),_c('ui-link',{staticClass:"ml-2",attrs:{"type":"success","uppercase":"","size":"small"},on:{"click":function($event){return _vm.handleNewLink()}}},[_vm._v(_vm._s(_vm.$t("links.new")))])],1)],1)],1),_c('ui-modal',{attrs:{"show":_vm.showDeleteModal,"text":_vm.$t('links.actions.confirm_delete'),"info":_vm.$t('links.info.delete_link_info'),"confirmText":_vm.$t('actions.delete')},on:{"confirm":function($event){return _vm.deleteRegistrationLink()},"cancel":function($event){_vm.showDeleteModal = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }